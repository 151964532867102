// ClientSlider.js
import React from "react";
import "./Clients.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

// Import client images
import Client1 from "../../assets/Images/VibhuClientLogos/AOIISchools.png";
import Client2 from "../../assets/Images/VibhuClientLogos/AmericanGully.png";
import Client3 from "../../assets/Images/VibhuClientLogos/AppleCare.jpg";
import Client4 from "../../assets/Images/VibhuClientLogos/CarLabs.png";
import Client5 from "../../assets/Images/VibhuClientLogos/DirectorateGeneral.png";
import Client6 from "../../assets/Images/VibhuClientLogos/EdiBuddy.png";
import Client7 from "../../assets/Images/VibhuClientLogos/FTCCI.png";
import Client8 from "../../assets/Images/VibhuClientLogos/GenomeValley.png";
import Client9 from "../../assets/Images/VibhuClientLogos/JGSI.png";
import Client10 from "../../assets/Images/VibhuClientLogos/Meditate.png";
import Client11 from "../../assets/Images/VibhuClientLogos/MeridianSchool.png";
import Client12 from "../../assets/Images/VibhuClientLogos/NeuroSurgeryNow.png";
import Client13 from "../../assets/Images/VibhuClientLogos/RamojiFilmCity.png";
import Client14 from "../../assets/Images/VibhuClientLogos/SJPSSchool.jpg";
import Client15 from "../../assets/Images/VibhuClientLogos/Soffaos.png";
import Client16 from "../../assets/Images/VibhuClientLogos/SriGiri.png";
import Client17 from "../../assets/Images/VibhuClientLogos/Suraksha.png";
import Client18 from "../../assets/Images/VibhuClientLogos/TV15.png";

const ClientSlider = () => {
  const clientImages = [
    Client1, Client2, Client3, Client4, Client5, Client6, Client7, 
    Client8, Client9, Client10, Client11, Client12, Client13, 
    Client14, Client15, Client16, Client17, Client18
  ];

  const settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <div className="client">
      <div className="client_cont">
        <div className="client_cont_header">
          <h1>We Win When Our Customers Win</h1>
        </div>
        <div className="client_cont_sec">
          <Slider {...settings}>
            {clientImages.map((client, index) => (
              <div key={index}>
                <img src={client} alt={`Client${index + 1}`} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ClientSlider;
