import React, { useState, useEffect } from "react";
import "./Header.scss";
import logo from "../../assets/Images/vibhuLogo.png";
import scrolledLogo from "../../assets/Images/vibhuLogo2.png";
import Button from "../Buttons/Button";

const Header = ({ showButton }) => {
  const [scroll, setScroll] = useState(false);
  const [logoSrc, setLogoSrc] = useState(logo);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > 0) {
        setScroll(true);
        setLogoSrc(scrolledLogo);
      } else {
        setScroll(false);
        setLogoSrc(logo);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    const phoneNumber = "9380345108";
    const message =
      "Thank you for reaching out to Vibhu Solutions! We specialize in Software Development, Mobile App Development, and Digital Marketing. Contact us today to discuss how we can help your business thrive.";

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappUrl, "_blank");
  };

  return (
    <div>
      <div className={scroll ? "nav scrolled" : "nav"}>
        <div className="nav_container">
          <div className="nav_container_left">
            <a
              href="https://vibhusolutions.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="img" src={logoSrc} alt="Company Logo" />
            </a>
          </div>
          <div className="nav_container_center">
            <ul>
              <a href="https://vibhusolutions.com/" target="_blank">
                <li>Home</li>
              </a>
              <a href="https://vibhusolutions.com/about-us" target="_blank">
                <li>Abouts Us</li>
              </a>
              <a href="https://vibhusolutions.com/" target="_blank">
                <li>Services</li>
              </a>
              <a href="https://vibhusolutions.com/contact-us" target="_blank">
                <li>Contact Us</li>
              </a>
              <a href="https://vibhusolutions.com/blogs" target="_blank">
                <li>Blogs</li>
              </a>
            </ul>
          </div>
          <div className="nav_container_right">
            {!showButton && (
              <Button
                text="Contact Us"
                type="button"
                onClick={handleScrollToTop}
                className="custom-button"
                variant="secondary"
                style={{ width: "150px" }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
