import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import LandingPage from "../pages/LandingPage/LandingPage";
import ThankYou from "../pages/ThankYou/ThankYou";

const RouterComponent = () => {
  return (
    <BrowserRouter>
    <Header />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/thank-you" element={<ThankYou />} />
  
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default RouterComponent;
