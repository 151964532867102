import React, { useEffect, useRef, useState } from "react";
import "./LandingPage.scss";
import { CountUp } from "countup.js";
import MyForm from "../../components/Form/Form";
import Vission from "../../assets/Images/Vission.png";
import AILogo from "../../assets/Images/IconAI.png";
import ARVRLogo from "../../assets/Images/IconARVR.png";
import DMLogo from "../../assets/Images/IconDM.png";
import WDLogo from "../../assets/Images/IconWD.png";
import MobileLogo from "../../assets/Images/IconMobile.png";
import AIimage from "../../assets/Images/AIdevelopment.jpg";
import ARVRimage from "../../assets/Images/ARVR development.png";
import IOTImage from "../../assets/Images/IOTapplications.jpg";
import MobileImage from "../../assets/Images/MobileAppDevelopment.jpg";
import DMImage from "../../assets/Images/DigitalMarketing.jpg";
import WDImage from "../../assets/Images/WebsiteDesigning.jpg";
import RealEstate from "../../assets/Images/RealEstateIndustry.jpg";
import ECommerce from "../../assets/Images/E-commerceIndustry.jpg";
import Healthcare from "../../assets/Images/HealthcareIndustry.jpg";
import Education from "../../assets/Images/EducationIndustry.jpg";
import FinancialServices from "../../assets/Images/FinancialServicesIndustry.jpg";
import bg from "../../assets/Images/bg.png";
import ClientSlider from "../../components/Clients/Clients";
import AccordionUsage from "../../components/Accordion/Accordion";
import { Helmet } from "react-helmet";

const LandingPage = () => {
  const [selectedIndustry, setSelectedIndustry] = useState("RealEstate");
  const counterRef = useRef(null);

  const industryImages = {
    RealEstate,
    ECommerce,
    Healthcare,
    Education,
    FinancialServices,
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          startCounters();
          observer.unobserve(entry.target);
        }
      });
    }, options);

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  const startCounters = () => {
    const countUpInstance = new CountUp("myCounter", 10, {
      duration: 3,
    });
    const countUpInstance1 = new CountUp("myCounter1", 5, {
      duration: 3,
    });
    const countUpInstance2 = new CountUp("myCounter2", 83, {
      duration: 3,
    });
    const countUpInstance3 = new CountUp("myCounter3", 25000, {
      duration: 3,
    });

    if (!countUpInstance.error) {
      countUpInstance.start();
    } else {
      console.error(countUpInstance.error);
    }

    if (!countUpInstance1.error) {
      countUpInstance1.start();
    } else {
      console.error(countUpInstance1.error);
    }

    if (!countUpInstance2.error) {
      countUpInstance2.start();
    } else {
      console.error(countUpInstance2.error);
    }

    if (!countUpInstance3.error) {
      countUpInstance3.start();
    } else {
      console.error(countUpInstance3.error);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Software Development, App Development & Digital Marketing Company
        </title>
        <meta
          name="description"
          content="Vibhu Solutions: Expert software development, app development, and digital marketing services. Transform your business with innovative tech solutions."
        />
      </Helmet>
      <div className="home">
        <div className="home_banner">
          <div className="home_container">
            <div className="home_container_sec">
              <div className="home_container_sec_left">
                <h1>
                  {" "}
                  Leading Software Development & Digital Marketing Company
                </h1>
                <p>
                  Welcome to Vibhu Solutions. We offer top-tier software
                  development, mobile app development, and digital marketing
                  services to help businesses drive growth and stay ahead of the
                  competition.
                </p>
              </div>
              <div className="home_container_sec_right">
                <MyForm />
              </div>
            </div>
          </div>
        </div>
        <div className="home_vision">
          <div className="home_vision_cont">
            <div className="home_vision_cont_header">
              <h1>Our Vision & Mission</h1>
            </div>
            <div className="home_vision_cont_sec">
              <div className="home_vision_cont_sec_left">
                <img src={Vission} alt="Our Vision" />
              </div>
              <div className="home_vision_cont_sec_right">
                <p>
                  To be the global leader in innovative tech solutions,
                  empowering businesses to achieve unparalleled success through
                  cutting-edge software development, mobile app development, and
                  digital marketing.
                </p>
                <p>
                  To revolutionize the digital landscape with bespoke technology
                  services that drive growth, efficiency, and excellence. Our
                  mission is to exceed client expectations by delivering
                  innovative and scalable solutions that enhance competitiveness
                  and maximize ROI.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="home_service">
          <div className="home_service_cont">
            <div className="home_service_cont_header">
              <h1>Our Services</h1>
            </div>
            <div className="home_service_cont_sec">
              <div className="home_service_cont_sec_card">
                <img src={WDImage} alt="ARVRimage" className="hover-image" />
                <div className="home_service_cont_sec_card_top">
                  <img src={WDLogo} alt="WD Icon" />
                </div>
                <div className="home_service_cont_sec_card_center">
                  <h2>Website Designing</h2>
                </div>
                <div className="home_service_cont_sec_card_bottom">
                  <p>
                    Create stunning, responsive websites that captivate and
                    convert visitors. Vibhu Solutions ensures your online
                    presence stands out and achieves results.
                  </p>
                </div>
              </div>
              <div className="home_service_cont_sec_card">
                <img
                  src={MobileImage}
                  alt="ARVRimage"
                  className="hover-image"
                />
                <div className="home_service_cont_sec_card_top">
                  <img src={MobileLogo} alt="Mobile Icon" />
                </div>
                <div className="home_service_cont_sec_card_center">
                  <h2>Mobile App Development</h2>
                </div>
                <div className="home_service_cont_sec_card_bottom">
                  <p>
                    Deliver seamless user experiences with custom mobile apps.
                    Vibhu Solutions brings your app ideas to life with
                    functionality and design that users love.
                  </p>
                </div>
              </div>
              <div className="home_service_cont_sec_card">
                <img src={DMImage} alt="ARVRimage" className="hover-image" />
                <div className="home_service_cont_sec_card_top">
                  <img src={DMLogo} alt="DM Icon" />
                </div>
                <div className="home_service_cont_sec_card_center">
                  <h2>Digital Marketing</h2>
                </div>
                <div className="home_service_cont_sec_card_bottom">
                  <p>
                    Boost your online presence and reach the right audience
                    effectively. Vibhu Solutions drives targeted traffic and
                    conversions with strategic digital marketing.
                  </p>
                </div>
              </div>
              <div className="home_service_cont_sec_card">
                <img src={AIimage} alt="AIimage" className="hover-image" />
                <div className="home_service_cont_sec_card_top">
                  <img src={AILogo} alt="AI Icons" />
                </div>
                <div className="home_service_cont_sec_card_center">
                  <h2>Gen AI Development</h2>
                </div>
                <div className="home_service_cont_sec_card_bottom">
                  <p>
                    Empower your business with AI solutions tailored to drive
                    innovation and efficiency. Vibhu Solutions helps you unlock
                    new potentials with our customized generative AI tools.
                  </p>
                </div>
              </div>
              <div className="home_service_cont_sec_card">
                <img src={ARVRimage} alt="ARVRimage" className="hover-image" />
                <div className="home_service_cont_sec_card_top">
                  <img src={ARVRLogo} alt="ARVR Icon" />
                </div>
                <div className="home_service_cont_sec_card_center">
                  <h2>AR/VR Developemnt</h2>
                </div>
                <div className="home_service_cont_sec_card_bottom">
                  <p>
                    Transform your ideas into immersive experiences with AR/VR
                    development. Vibhu Solutions brings your vision to life,
                    engaging your audience like never before.
                  </p>
                </div>
              </div>
              <div className="home_service_cont_sec_card">
                <img src={IOTImage} alt="ARVRimage" className="hover-image" />
                <div className="home_service_cont_sec_card_top">
                  <img src={MobileLogo} alt="IOT Icon" />
                </div>
                <div className="home_service_cont_sec_card_center">
                  <h2>IOT Application</h2>
                </div>
                <div className="home_service_cont_sec_card_bottom">
                  <p>
                    Enhance productivity and streamline operations with smart
                    IoT solutions. Vibhu Solutions connects and integrates your
                    devices seamlessly, bringing your world under control.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home_count">
          <div className="home_count_cont">
            <div className="home_count_cont_sec">
              <div className="home_count_cont_sec_content" ref={counterRef}>
                <h1>
                  <span id="myCounter"></span>+
                </h1>
                <strong>Clients</strong>
              </div>
              <div className="home_count_cont_sec_content">
                <h1>
                  <span id="myCounter1"></span>+
                </h1>
                <strong>Countries</strong>
              </div>
              <div className="home_count_cont_sec_content">
                <h1>
                  <span id="myCounter2"></span>%
                </h1>
                <strong>Repeat business</strong>
              </div>
              <div className="home_count_cont_sec_content">
                <h1>
                  <span id="myCounter3"></span>+
                </h1>
                <strong>Hours of code</strong>
              </div>
            </div>
          </div>
        </div>
        <div className="home_ind">
          <div className="home_ind_cont">
            <div className="home_ind_cont_header">
              <h1>Industries</h1>
            </div>
            <div className="home_ind_cont_sec">
              <div className="home_ind_cont_sec_left">
                <img
                  src={industryImages[selectedIndustry]}
                  alt={selectedIndustry}
                />
              </div>
              <div className="home_ind_cont_sec_right">
                <h2
                  onMouseEnter={() => setSelectedIndustry("RealEstate")}
                  className={selectedIndustry === "RealEstate" ? "hovered" : ""}
                >
                  Real Estate Industry
                </h2>
                <h2
                  onMouseEnter={() => setSelectedIndustry("ECommerce")}
                  className={selectedIndustry === "ECommerce" ? "hovered" : ""}
                >
                  E-commerce Industry
                </h2>
                <h2
                  onMouseEnter={() => setSelectedIndustry("Healthcare")}
                  className={selectedIndustry === "Healthcare" ? "hovered" : ""}
                >
                  Healthcare Industry
                </h2>
                <h2
                  onMouseEnter={() => setSelectedIndustry("Education")}
                  className={selectedIndustry === "Education" ? "hovered" : ""}
                >
                  Education Industry
                </h2>
                <h2
                  onMouseEnter={() => setSelectedIndustry("FinancialServices")}
                  className={
                    selectedIndustry === "FinancialServices" ? "hovered" : ""
                  }
                >
                  Financial Services Industry
                </h2>
              </div>
            </div>
          </div>
        </div>
        <ClientSlider />
        <AccordionUsage />
      </div>
    </>
  );
};

export default LandingPage;
