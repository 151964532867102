import React from "react";
import "./Footer.scss";
import logo from "../../assets/Images/vibhuLogo.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa6";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer_cont">
        <div className="footer_cont_sec">
          <div className="footer_cont_sec_left">
            <img src={logo} alt="logo" />
            <p>
              “Driving your business forward with secure and sustainable IT
              solutions, ensuring technological agility, and fueling innovative
              growth in the digital era!”
            </p>
            <ul>
              <a href="https://www.facebook.com/people/Vibhu-Solutions/100095480073493/?name=xhp_nt__fb__action__open_user" target="_blank">
                <FaFacebookF />
                <li>Facebook</li>
              </a>
              <a href="https://www.instagram.com/vibhusolutions/" target="_blank">
                <FaInstagram />
                <li>Instagram</li>
              </a>
              <a href="https://www.linkedin.com/company/vibhu-solutions" target="_blank">
                <FaLinkedin />
                <li>LinkedIn</li>
              </a>
              <a href="https://api.whatsapp.com/send?phone=919380345108" target="_blank">
                <FaWhatsapp />
                <li>Whatsapp</li>
              </a>
            </ul>
          </div>
          <div className="footer_cont_sec_right">
            <div className="footer_cont_sec_right_links">
              <div className="footer_cont_sec_right_links_header">
                <h2>Useful Links</h2>
              </div>
              <div className="footer_cont_sec_right_links_content">
                <ul>
                  <a href="https://vibhusolutions.com/" target="_blank"><li>Home</li></a>
                  <a href="https://vibhusolutions.com/about-us" target="_blank"><li>Abouts Us</li></a>
                  <a href="https://vibhusolutions.com/contact-us" target="_blank"><li>Contact Us</li></a>
                  <a href="https://vibhusolutions.com/blogs" target="_blank"><li>Blogs</li></a>
                  <a href="https://vibhusolutions.com/privacy-policy" target="_blank"><li>Privacy Policy</li></a>
                </ul>
              </div>
            </div>
            <div className="footer_cont_sec_right_services">
              <div className="footer_cont_sec_right_services_header">
                <h2>Services</h2>
              </div>
              <div className="footer_cont_sec_right_services_content">
                <ul>
                  <a href="https://vibhusolutions.com/services/web-development" target="_blank"><li>Web development</li></a>
                  <a href="https://vibhusolutions.com/services/app-development" target="_blank"><li>App development</li></a>
                  <a href="https://vibhusolutions.com/services/ui-ux-design" target="_blank"><li>UI/ UX Design</li></a>
                  <a href="https://vibhusolutions.com/services/graphic-design" target="_blank"><li>Graphics Design</li></a>
                  <a href="https://vibhusolutions.com/services/digital-marketing" target="_blank"><li>Digital Marketing</li></a>
                </ul>
              </div>
            </div>
            <div className="footer_cont_sec_right_contact">
              <div className="footer_cont_sec_right_contact_header">
                <h2>Contact Us</h2>
              </div>
              <div className="footer_cont_sec_right_contact_content">
                <h3>
                  #57, 2nd floor, 2rd cross, 80 Feet Road, Bhuvaneshwari nagar,
                  5th Block, BSK 3rd Stage, Bengaluru - 560085
                </h3>
                <p>+91 93803 45108</p>
                <p>contact@vibhusolutions.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <p>© Copyright Vibhu Solutions. All Rights Reserved</p>
      </div>
    </div>
  );
};

export default Footer;
