import React, { useState } from "react";
import Button from "../Buttons/Button";
import "./Form.scss";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import MuiPhoneNumber from "mui-phone-number";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";

const MyForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  // const services = [
  //   { value: "ai_development", label: "AI Development" },
  //   { value: "website_design", label: "Website Design" },
  //   { value: "web_mobile_app", label: "Web & Mobile App Development" },
  //   { value: "blockchain_development", label: "Blockchain Development" },
  //   { value: "digital_marketing", label: "Digital Marketing" },
  // ];

  async function onSubmit(data) {
    setIsSubmitting(true);
    try {
      const url = "https://api.vibhusolutions.com/api/Enquiry/AddEnquiry";
      const payload = {
        websiteId: 3,
        email: data.companyEmail,
        name: data.fullName,
        message: data.description,
        phoneNumber: data.phone,
      };
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        navigate("/thank-you", { state: { showButton: true } });
      } else {
        console.error("Failed to submit form:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    setIsSubmitting(false);
  }
  return (
    <div className="contact">
      <div className="contact_container">
        <h1>Let’s Get Started</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            {/* Full Name */}
            <Grid item xs={12}>
              <Controller
                name="fullName"
                control={control}
                defaultValue=""
                rules={{
                  required: "Full Name is required",
                  minLength: {
                    value: 6,
                    message: "Fullname must be at least 6 characters",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Full Name"
                    variant="outlined"
                    required
                    fullWidth
                    error={!!errors.fullName}
                    helperText={errors.fullName?.message}
                    size="small"
                  />
                )}
              />
            </Grid>
            {/* Company Email */}
            <Grid item xs={12}>
              <Controller
                name="companyEmail"
                control={control}
                defaultValue=""
                rules={{
                  required: "Company Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Company Email"
                    variant="outlined"
                    required
                    fullWidth
                    error={!!errors.companyEmail}
                    helperText={errors.companyEmail?.message}
                    size="small"
                  />
                )}
              />
            </Grid>
            {/* Request for Services */}
            {/* <Grid item xs={12}>
              <Controller
                name="requestForServices"
                control={control}
                defaultValue=""
                rules={{ required: "Request for Services is required" }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label="Request for Services?"
                    variant="outlined"
                    required
                    fullWidth
                    error={!!errors.requestForServices}
                    helperText={errors.requestForServices?.message}
                  >
                    {services.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid> */}
            {/* Phone Number */}
            <Grid item xs={12}>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                rules={{ required: "Phone number is required" }}
                render={({ field }) => (
                  <MuiPhoneNumber
                    {...field}
                    defaultCountry="in"
                    error={!!errors.phoneNumber}
                    helperText={
                      errors.phoneNumber ? "Phone Number is required" : ""
                    }
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
            {/* Address */}
            {/* <Grid item xs={12}>
              <Controller
                name="address"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Address"
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </Grid> */}
            {/* Description */}
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Description"
                    variant="outlined"
                    required
                    fullWidth
                    size="small"
                    multiline
                    rows={4}
                  />
                )}
              />
            </Grid>
          </Grid>
          <div className="btn">
            {isSubmitting ? (
              <Loader isStyles={true} />
            ) : (
              <Button
                text="Submit"
                type="submit"
                className="custom-button"
                variant="contained"
              >
                Submit
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MyForm;
