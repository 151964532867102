import React from 'react'
import "./Button.scss";
const Button = ({ text, type, onClick, className, variant, style }) => {
  return (
    <div>
       <button type={type} onClick={onClick} className={`custom-button ${className} ${variant}`} style={style}>
       {text}
       </button>
       
    </div>
  )
}

export default Button