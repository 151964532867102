import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Accordion.scss";

export default function AccordionUsage() {
  return (
    <div className="accordion">
      <div className="accordion_cont">
        <div className="accordion_cont_header">
          <h1>Frequently Asked Questions</h1>
        </div>
        <div className="accordion_cont_sec">
          <div className="accordion_cont_sec_left">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Q.Why Choose Vibhu Solutions?
              </AccordionSummary>
              <AccordionDetails>
                A.Vibhu Solutions enhances operational efficiency and
                competitiveness with tailored IT solutions across various
                industries. Our expertise in software development, mobile app
                development, and digital marketing ensures flexible, scalable
                services to optimize your IT operations and maximize technology
                investments.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Q.What makes Vibhu Solutions different from other IT service
                providers?
              </AccordionSummary>
              <AccordionDetails>
                A.Our commitment to custom solutions tailored to each client’s
                specific needs sets us apart. We provide exceptional value and
                service through our expertise in software development, mobile
                app development, and digital marketing, along with ongoing
                support and flexibility.
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="accordion_cont_sec_right">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                Q.How quickly can Vibhu Solutions respond to IT issues?
              </AccordionSummary>
              <AccordionDetails>
                A.We offer rapid response times with our dedicated support team
                available 24/7 to promptly address and resolve IT issues,
                minimizing downtime and maintaining business continuity.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                Q.How to find and choose a custom software development firm?
              </AccordionSummary>
              <AccordionDetails>
                A.Look for expertise, reputation, and service offerings. Vibhu
                Solutions excels in software development, mobile app
                development, and digital marketing, providing comprehensive and
                customized solutions for your industry needs.
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}
